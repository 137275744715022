import React, { useState, useEffect, useContext } from "react";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import ZodiacDD from "../../../components/ZodiacDD";
import banner from "../../../staticAssets/Horoscope Bg.png";
import logo from "../../../staticAssets/Libra.png";
import axios from "axios";
import { navigate } from "gatsby";
import { AuthContext } from "../../../context/Auth";
import { Helmet } from "react-helmet-async";
import { AnalyticsContext } from "../../../context/Analytics";

function Index({location}) {
  const [blogs, setblogs] = useState([]);
const { gtmPageViewEvent } = useContext(AnalyticsContext) || {
    gtmPageViewEvent: () => {},
  };
  const { user } = useContext(AuthContext) || {
    user: {},
  };
  useEffect(() => {
    const fn = async () => {
      let res = await axios.get(
        "https://www.bodhi.app/blog/wp-json/wp/v2/posts"
      );

      if (res.status == 200) {
        setblogs([...res.data]);
      }
    };
    gtmPageViewEvent(window.location.pathname, window.location.host);
fn();
  }, []);
  return (
    <div className="h-screen flex flex-col block hide-scroll-bar scrollbar-hide worksans w-screen overflow-x-clip">
      <Header />
      <Helmet>
        <link
          rel="canonical"
          href={"https://www.bodhi.app"+location?.pathname}
        />
      </Helmet>
      <div className="flex-1 overflow-y-auto flex flex-col">
        <main className="grow hide-scroll-bar scrollbar-hide">
          <div className=" w-full relative bg-[#FFEEEF] overflow-hidden">
            <img className="h-32 md:h-auto w-full object-fill" src={banner} />
            <div className="absolute top-0 w-full h-full flex justify-center items-center">
              <h1 className="text-center text-2xl md:text-4xl font-semibold text-white">
                Libra Career
              </h1>
            </div>
          </div>
          <div className="container mx-auto flex flex-col">
            <div className="flex flex-wrap my-2 ml-2">
              <a
                href="https://www.bodhi.app/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Bodhi</h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Zodiac Signs</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/libra"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Libra</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>
              <h4 className="text-sm">Libra Career</h4>
              
            </div>
            <div className="flex mt-10 items-center">
              <div>
                <img className="h-24 md:h-48" src={logo} />
              </div>
              <div className="flex-1 flex flex-col md:flex-row">
                <div className=" flex-1 flex flex-col">

                <h1 className="font-semibold text-xl md:text-4xl ml-4">Libra Career </h1>
                <h2 className="text-sm md:text-base ml-4">Sep 23 - Oct 23</h2>
                </div>
                <div className="">
                <ZodiacDD name={"Change Sunsign"} />
              </div>
              </div>
              
            </div>
            <div className="grid grid-cols-3 md:grid-cols-4 gap-2 md:gap-8 mt-10 text-[0.8rem] md:text-base mx-1">
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2 py-1" onClick={() => {
                    navigate("/zodiac-signs/libra/libra-woman");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Libra Woman</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>

              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/libra/libra-health");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Libra Health</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/libra/libra-career");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Libra Career</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/libra/libra-love");
                  }}>
                  <h5 className="font-semibold text-black">Libra Love</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/libra/libra-nature");
                  }}>
                  <h5 className="font-semibold text-black">Libra Nature</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/libra/libra-man");
                  }}>
                  <h5 className="font-semibold text-black">Libra Man</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/libra/libra-relationship");
                  }}>
                  <h5 className="font-semibold text-black">Libra Relationship</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/libra/libra-traits");
                  }}>
                  <h5 className="font-semibold text-black">Libra Traits</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/libra/libra-facts");
                  }}>
                  <h5 className="font-semibold text-black">Libra Facts</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
            </div>
            <span className="my-4 mx-1 md:mx-0">
                 <p className="playfair text-black text-xl md:text-3xl">Libra Career</p><br/>
                 <p className="font-bold text-orange-500 inline-block mr-2">Career Mantra of Libra:</p>whatever I can dream, I can achieve it 
                 <br></br>
                 <p className="font-bold text-orange-500 inline-block mr-2">Lessons to be given:</p>Creativity, imagination, maintaining harmony and peace, mental attention, collaborating with people, balancing, and justice
                 <br></br>
                 <p className="font-bold text-orange-500 inline-block mr-2">Things you need to learn:</p>Standing up for yourself, being straightforward and firm, speaking complicated truth, overwhelming complacent and laziness
                 <br></br>
                 <br></br>
                 <p className="playfair text-black text-xl md:text-3xl">Libra and Career</p><br/>
                 Librans are more inclined towards a career path that allows them innovative self-expression. They are very innovative and always try to make their jobs fun. Imagination is Libra’s most outstanding career strength. For you, the workplace is like grassland. You generally do your projects your way and manner until you find them challenging. It would help if you had variety and a regular discussion of ideas at the workplace. <br></br>
                <br></br>
                Libra natives are mentally awake, attentive, and outgoing. The Libra native is very curious and keen to learn new things. You enjoy the perks of senior job titles. Know your favorable timelines for upgrades or promotions with your Free Personalized Janampatri. You will make honest efforts towards the embarked work and always make sure that your team or the employees working with you are comfortable, happy, and satisfied. <br></br> 
                <br></br>
                You are collaborative and diplomatic, so people like to work with you. Although you are well-liked and famous, you can sometimes be somewhat aloof. A friendly, cooperative, and attractive environment in the workplace are essential for you. So you work towards creating peace and harmony and opt for a lovely and peaceful environment. You are firm believers in fair play and will never accept injustice to yourselves or others around you. <br></br>
                <br></br>
                You may not be temperamentally suitable for jobs that are messy or risky. You like to work in space or a profession that allows you to create and get opposing things into balance. Your grace, elegance, and diplomacy in the workplace are liked by the people performing with you. You never go for an unethical approach as you believe in human relations. You possess exceptional advisory skills and are willing to help people anytime. <br></br>
                <br></br>
                All kinds of work that need the exercise of your intelligence request you, and you would love to make a career out of such work. How will you recognize how to overpower adversaries for career development? Get your Free Personalized 2024 Report by the best Astrologer! <br></br>
                <br></br>
                <p className="playfair text-black text-xl md:text-3xl">Best Career Options for Libra</p><br/>
                Beauty-loving Venus rules you so that you may be inclined toward the work in the industry related to art, beauty, fashion, and design. As you naturally comprehend balance, you can excel as a designer, graphic designer, or engineer. You are a peacemaker and will bound into the fray as a sociable justice champion, peace activist, or even an attorney.  <br></br>
                <br></br>
                You are not favored by nature so that you can perform in the justice and legal sphere of a career like a judge, lawyer, and arbitrator. <br></br>
                <br></br>
                You are regularly indulged in evaluating and measuring details and can earn a reputation as a wise judge or a source of powerful beliefs. The other career opportunities that may best suit you are a journalist, fashion designer, interior decorator, creative director, artist, publicist, party promoter, makeup artist, food critic, human rights advocate, filmmaker, mediator, and a broker. You can be a counsel, sports trainer, and agent as you are unbiased and have a tendency to see the condition in a neutral form. <br></br>
                <br></br>
                <p className="playfair text-black text-xl md:text-3xl">Libra and Money</p><br/>
                Libra is not a frugal sign; they will spend money to make money. When spending your hard-earned money, you often take a prolonged time to decide. You genuinely relish money but also wish to have the best of everything in life by expending cash from your savings. Libra people have a craving for expensive, sumptuous, and luxurious goods. Thus, you find a way to earn money to have all the desired things in your life. <br></br>
                <br></br>
                Check your daily horoscope, weekly horoscope, monthly horoscope, and yearly horoscope are absolutely free..!! <br></br>
                <br></br>
            </span>
          </div>
          <div className="container mx-auto">
            <div className="flex justify-between mb-4 mx-2">
              <h1 className="text-xl md:text-3xl text-black playfair">
                Recent Blogs
              </h1>
              <a
                className="text-sm md:text-md text-orange-500 text-center font-semibold"
                href="https://www.bodhi.app/blog/"
              >
                See More
              </a>
            </div>
            <div className="relative">
                <div className="absolute top-0 right-0 w-0 md:w-36 fixed h-full bg-gradient-to-r from-transparent to-white"></div>
              <div className="flex overflow-x-scroll pb-4 mt-2 hide-scroll-bar scrollbar-hide">
                <div className="flex flex-nowrap ml-2">
                  {blogs.slice(0, 10).map((item, i) => {
                    return (
                      <a href={item.link} key={i} target="_blank">
                        <div className="flex flex-col h-80 w-52 justify-start items-start bg-gray-100 mr-10 mb-10 rounded-lg overflow-hidden hover:shadow-lg">
                          <img
                            className="object-cover w-52 h-52 w-auto bg-gray-100"
                            src={
                              item?.yoast_head_json?.og_image
                                ? item?.yoast_head_json?.og_image[0]?.url
                                : ""
                            }
                          />
                          <span className="mt-2 block mx-2 text-center">
                            {item?.yoast_head_json?.title ?? ""}
                          </span>
                        </div>
                      </a>
                    );
                  })}
                  {/* <div className="flex h-80 w-52 justify-center items-center "></div> */}
                </div>
              </div>
            </div>
          <button
            className="justify-center text-white fixed right-2 bottom-2 rounded-t-2xl but px-4 py-2 flex gap-1 shadow-xl"
            onClick={() => {
              navigate("/consultAstro");
            }}
          >
            {user.name !== undefined?`Consult Top Astrologers Now!`:`Login & get ${
              user?.currencyType == "usd" ? "$ 4" : "₹ 91"
            } in your account!`}
          </button>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
}

export default Index;
